.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.login-form {
  margin: 5% auto;
    width: 15rem;
    border: 2px solid grey;
    border-radius: 5px;
    font-size: 1.2rem;
    background:#e5e5e5;
    box-shadow: 5px 1px 20px #111;
    display:flex;
    flex-direction: column;
    padding: 5rem;
    align-items: center;
 
  }

  .signup-form {
    margin: 5% auto;
    width: 15rem;
    border: 2px solid grey;
    border-radius: 5px;
    font-size: 1.2rem;
    background:#e5e5e5;
    box-shadow: 5px 1px 20px #111;
    display:flex;
    flex-direction: column;
    padding: 5rem;
    align-items: center;
  
    
    }

    .signup-input{
      border:1px solid red;
      width:50%;
      height: 25%;
    }